import "../mystyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTerminal,
  faServer,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faJs, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as Avatar } from "../imgs/avatar.svg";

export const Head = () => {
  return (
    <>
      <section className="section is-large">
        <div className="container">
          <div className="columns is-desktop is-vcentered">
            <div className="column has-text-centered">
              <Avatar />
            </div>
            <div className="column is-four-fifths-desktop">
              <h1 className="title is-2 has-text-centered">
                Hi, my name is{" "}
                <strong className="has-text-primary-dark">Nithiiyan</strong>,
                but you can call me{" "}
                <strong className="has-text-primary-dark">Nith</strong>.
              </h1>
              <h2 className="subtitle is-4 has-text-centered">
                I'm a software engineer, comp-sci graduate, and an avid developer
              </h2>
            </div>
          </div>
        </div>
      </section>
      <Info />
      <Cards />
      <Socials />
    </>
  );
};

const Info = () => {
  return (
    <section className="section">
      <div className="container has-text-centered">
        <div className="title">Nice to meet you!</div>
        <div className="subtitle">
          I like to think of myself as a curious guy. Since I've started down
          the path of becoming a developer, I've dabbled in whatever has
          interested me the most. This includes web development, backend
          development, cloud infrastructure and startups!
        </div>
      </div>
    </section>
  );
};

const Cards = () => {
  return (
    <section className="section has-text-centered">
      <div className="container is-narrow">
        <h1 className="title">
          Developing in a{" "}
          <strong className="has-text-primary-dark">Full-stack</strong> Capacity
        </h1>
        <div className="subtitle">
          I'm always learning and excited to test out new technologies, so this
          list isn't comprehensive, and is always growing!
        </div>
        <div className="box">
          <div className="content">
            <div className="columns is-centered">
              <div className="column">
                <span className="icon is-large">
                  <FontAwesomeIcon icon={faJs} size="3x" />
                </span>
                <h1 className="title is-size-4 is-spaced has-text-primary-dark">
                  Front-end
                </h1>
                <h2 className="subtitle is-size-6">
                  I like to design simple, functional things, with a focus on
                  speed and scalability whenever possible
                </h2>
                <h2 className="subtitle is-size-5 has-text-primary-dark">
                  Things I've used:
                </h2>
                <div>React</div>
                <div>Bulma</div>
                <div>Figma</div>
                <div>Sass</div>
                <div>Tailwind CSS</div>
                <div>Vue</div>
                <div>Sveltekit</div>
                <div>Jest Testing</div>
                <div>JQuery</div>
                <div>Javascript</div>
                <div>Good ol' Pen and Paper!</div>
              </div>
              <div className="column">
                <span className="icon is-large">
                  <FontAwesomeIcon icon={faTerminal} size="3x" />
                </span>
                <h1 className="title is-size-4 is-spaced has-text-primary-dark">
                  Back-end
                </h1>
                <h2 className="subtitle is-size-6">
                  Robust apps require robust backend systems. I've learned what
                  that looks like, and how large companies do it at scale.
                </h2>
                <h2 className="subtitle is-size-5 has-text-primary-dark">
                  Things I've used:
                </h2>
                <div>Go Microservices</div>
                <div>Node.js</div>
                <div>Protocol Buffers</div>
                <div>GraphQL Subscriptions</div>
                <div>PostgreSQL</div>
                <div>REST API</div>
                <div>Java SpringBoot</div>
              </div>
              <div className="column">
                <span className="icon is-large">
                  <FontAwesomeIcon icon={faServer} size="3x" />
                </span>
                <h1 className="title is-size-4 is-spaced has-text-primary-dark">
                  Infrastructure
                </h1>
                <h2 className="subtitle is-size-6">
                  Scalable apps need scalable, fault-resilient infrastructure.
                  I'm AWS certified and have experience in building
                  infrastructure-as-code efficiently and with uptime and
                  recovery in mind.
                </h2>
                <h2 className="subtitle is-size-5 has-text-primary-dark">
                  Things I've used:
                </h2>
                <div>AWS</div>
                <div>Docker</div>
                <div>Kubernetes</div>
                <div>Terraform</div>
                <div>Cloudformation</div>
                <div>Linux-based Systems</div>
                <div>Vercel</div>
                <div>Firebase</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Socials = () => {
  return (
    <section className="section has-text-centered">
      <div className="container is-narrow">
        <h1 className="title is-primary">
          Checkout my <strong className="has-text-primary-dark">Socials</strong>
          :
        </h1>
        <div className="columns is-centered is-desktop">
          <div className="column is-2">
            <a href="https://www.linkedin.com/in/nithiiyan-skhanthan-07255a186/">
              {" "}
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </a>
          </div>
          <div className="column is-2">
            <a href="https://github.com/nskha101">
              {" "}
              <FontAwesomeIcon icon={faGithub} size="3x" />
            </a>{" "}
          </div>
          <div className="column is-2">
            <a href="mailto:nithiiyan25@gmail.com">
              {" "}
              <FontAwesomeIcon icon={faEnvelope} size="3x" />
            </a>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};
