import { Head } from "./components/Head";
import ParticleBackground from "./components/ParticleBackground";
const App = () => {
  return (
    <>
      <script
        src="https://kit.fontawesome.com/36b504c929.js"
        crossorigin="anonymous"
      ></script>
      {/* <Nav /> */}
      <ParticleBackground />
      <Head />
    </>
  );
};

export default App;
